import { gql } from "@apollo/client/core";

export const LOGIN = gql`
  mutation login($input: Login!) {
    login(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($hash: String!) {
    confirmEmail(hash: $hash)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      key
      rev
      type
      dni
      name
      lastName
      lastName2
      email
      company
      phone
      createdAt
      currentRole {
        id
        key
        rev
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($key: ID!, $condition: ConditionUserDelete) {
    deleteUser(key: $key, condition: $condition)
  }
`;

export const CREATE_WORK = gql`
  mutation createWork($input: CreateWorkInput!) {
    createWork(input: $input) {
      id
      key
      rev
      name
      image
      roles {
        id
        key
        rev
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
        createdAt
      }
      createdAt
    }
  }
`;

export const UPDATE_WORK = gql`
  mutation updateWork($input: UpdateWorkInput!) {
    updateWork(input: $input) {
      id
      key
      rev
      name
      image
      roles {
        id
        key
        rev
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
        createdAt
      }
      createdAt
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
      key
      rev
      name
      permissions {
        module
        hasMobilePermissions
        hasWebPermissions
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
      key
      rev
      name
      permissions {
        module
        hasMobilePermissions
        hasWebPermissions
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($key: ID!) {
    deleteRole(key: $key)
  }
`;

export const CREATE_SPECIALITY = gql`
  mutation createSpeciality($input: CreateSpecialityInput!) {
    createSpeciality(input: $input) {
      id
      key
      rev
      name
      createdAt
    }
  }
`;

export const CREATE_MEMBER = gql`
  mutation createMember($input: CreateMemberInput!, $workKey: ID!) {
    createMember(input: $input) {
      id
      key
      rev
      type
      dni
      name
      lastName
      lastName2
      email
      company
      phone
      createdAt
      category(workKey: $workKey) {
        id
        key
        name
      }
      speciality(workKey: $workKey) {
        id
        key
        name
      }
      currentRole(workKey: $workKey) {
        id
        key
        rev
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
      }
    }
  }
`;

export const CREATE_MEMBER_EXISTS = gql`
  mutation createUserExists($input: CreateUserExistsInput!, $workKey: ID!) {
    createUserExists(input: $input) {
      id
      key
      name
      lastName
      lastName2
      email
      company
      phone
      dni
      userEntity
      speciality(workKey: $workKey) {
        id
        key
        name
      }
      category(workKey: $workKey) {
        id
        key
        name
      }
      currentRole(workKey: $workKey) {
        id
        key
        name
      }
    }
  }
`;

export const CREATE_MEMBER_USER_EXISTS = gql`
  mutation createUserAdminExists($input: CreateUserAdminExistsInput!) {
    createUserAdminExists(input: $input) {
      id
      key
      name
      lastName
      lastName2
      email
      company
      phone
      dni
      userEntity
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation updateMember($input: UpdateMemberInput!, $workKey: ID!) {
    updateMember(input: $input) {
      id
      key
      rev
      type
      dni
      name
      lastName
      lastName2
      email
      company
      phone
      createdAt
      category(workKey: $workKey) {
        id
        key
        name
      }
      speciality(workKey: $workKey) {
        id
        key
        name
      }
      currentRole(workKey: $workKey) {
        id
        key
        rev
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
      }
    }
  }
`;

export const CREATE_WORKER = gql`
  mutation createWorker($input: CreateWorkerInput!, $workKey: ID!) {
    createWorker(input: $input) {
      id
      key
      rev
      type
      dni
      name
      lastName
      lastName2
      email
      company
      phone
      code
      admissionDate
      customSpeciality
      createdAt
      category(workKey: $workKey) {
        id
        key
        name
      }
      speciality(workKey: $workKey) {
        id
        key
        name
      }
      currentRole(workKey: $workKey) {
        id
        key
        rev
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
      }
    }
  }
`;

export const UPDATE_WORKER = gql`
  mutation updateWorker($input: UpdateWorkerInput!, $workKey: ID!) {
    updateWorker(input: $input) {
      id
      key
      rev
      type
      dni
      name
      lastName
      lastName2
      email
      company
      phone
      code
      admissionDate
      customSpeciality
      createdAt
      category(workKey: $workKey) {
        id
        key
        name
      }
      speciality(workKey: $workKey) {
        id
        key
        name
      }
      currentRole(workKey: $workKey) {
        id
        key
        rev
        name
        permissions {
          module
          hasMobilePermissions
          hasWebPermissions
        }
      }
    }
  }
`;

export const IMPORT_MEMBERS = `
  mutation CreateMemberExcel($input: CreateUserExcelInput!) {
    createMemberExcel(input: $input)
  }
`;

export const IMPORT_CA_WORKERS = gql`
  mutation importCAWorkers($input: ImportCAWorkersInput!) {
    importCAWorkers(input: $input)
  }
`;

export const IMPORT_DEPARTURES = `
  mutation CreateDepartureExcel($input: CreateDepartureExcelInput!) {
    createDepartureExcel(input: $input)
  }
`;

export const CREATE_DEPARTURE = gql`
  mutation createDeparture($input: CreateDepartureInput!) {
    createDeparture(input: $input) {
      id
      key
      rev
      name
      code
      budget
      unit
      budgetIp
      budgetMetered
      budgetHours
      goalHours
      startDate
      endDate
      isToCalculateIp
      isSubDeparture
      parentDepartureKey
      categoryCapatazBudget
      categoryOperarioBudget
      categoryOficialBudget
      categoryAyudanteBudget
      createdAt
    }
  }
`;

export const UPDATE_DEPARTURE = gql`
  mutation updateDeparture($input: UpdateDepartureInput!) {
    updateDeparture(input: $input) {
      id
      key
      rev
      name
      code
      budget
      unit
      budgetIp
      budgetMetered
      budgetHours
      goalHours
      startDate
      endDate
      isToCalculateIp
      isSubDeparture
      parentDepartureKey
      categoryCapatazBudget
      categoryOperarioBudget
      categoryOficialBudget
      categoryAyudanteBudget
      subdepartures {
        id
        key
        rev
        name
        code
        budget
        unit
        budgetIp
        budgetMetered
        budgetHours
        goalHours
        startDate
        endDate
        isToCalculateIp
        isSubDeparture
        categoryCapatazBudget
        categoryOperarioBudget
        categoryOficialBudget
        categoryAyudanteBudget
      }
    }
  }
`;

export const DELETE_DEPARTURE = gql`
  mutation deleteDeparture($key: ID!) {
    deleteDeparture(key: $key)
  }
`;

export const CREATE_GROUP = gql`
  mutation createGroup($input: CreateGroupInput!, $workKey: ID!) {
    createGroup(input: $input) {
      id
      key
      rev
      name
      createdAt
      foreman {
        key
        type
        dni
        name
        lastName
        lastName2
        email
        company
        phone
        speciality(workKey: $workKey) {
          key
          name
        }
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($input: UpdateGroupInput!, $workKey: ID!) {
    updateGroup(input: $input) {
      id
      key
      rev
      name
      createdAt
      foreman {
        key
        type
        dni
        name
        lastName
        lastName2
        email
        company
        phone
        speciality(workKey: $workKey) {
          key
          name
        }
      }
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($key: ID!) {
    deleteGroup(key: $key)
  }
`;

export const CREATE_GROUP_MEMBER = gql`
  mutation createGroupMember($input: CreateGroupMemberInput!, $workKey: ID!) {
    createGroupMember(input: $input) {
      key
      user {
        key
        type
        dni
        name
        lastName
        lastName2
        email
        company
        phone
        speciality(workKey: $workKey) {
          key
          name
        }
      }
    }
  }
`;

export const DELETE_GROUP_MEMBER = gql`
  mutation deleteGroupMember($key: ID!) {
    deleteGroupMember(key: $key)
  }
`;

export const SAVE_GROUP_DEPARTURES = gql`
  mutation saveGroupDepartures($input: SaveGroupDeparturesInput!) {
    saveGroupDepartures(input: $input) {
      key
      departure {
        key
        name
      }
    }
  }
`;

export const SAVE_GROUP_SECTORS = gql`
  mutation saveGroupSectors($input: SaveGroupSectorsInput!) {
    saveGroupSectors(input: $input) {
      key
      sector {
        key
        name
      }
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation createSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      id
      key
      weekKey
      name
      date
      hour
      status
      color
      timeout
      isToDispatch
      sort
      metered
      unit
      departure {
        id
        key
        name
      }
      user {
        id
        key
        name
        lastName
        lastName2
        email
        dni
        company
      }
      users {
        id
        key
        name
        lastName
        lastName2
        email
        dni
        company
      }
      sector {
        key
        name
      }
      cause {
        id
        key
        types {
          id
          key
          name
        }
        cause
      }
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation updateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      id
      key
      weekKey
      name
      date
      hour
      status
      isToDispatch
      timeout
      sort
      color
      unit
      metered
      departure {
        id
        key
        name
      }
      user {
        id
        key
        name
        lastName
        lastName2
        email
        dni
        company
      }
      users {
        id
        key
        name
        lastName
        lastName2
        email
        dni
        company
      }
      sector {
        key
        name
      }
      cause {
        id
        key
        types {
          id
          key
          name
        }
        cause
      }
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation deleteSchedule($key: ID!) {
    deleteSchedule(key: $key)
  }
`;

export const CREATE_MARKED_SCHEDULE = gql`
  mutation createMarkedSchedule($input: CreateMarkedScheduleInput!) {
    createMarkedSchedule(input: $input) {
      key
      weekKey
      name
      date
      hour
      status
      color
      timeout
      isToDispatch
      sort
      metered
      unit
      scheduleKey
      departure {
        key
        name
      }
      user {
        key
        name
        lastName
        lastName2
        email
        dni
        company
      }
      users {
        key
        name
        lastName
        lastName2
        email
        dni
        company
      }
      sector {
        key
        name
      }
      cause {
        key
        types {
          key
          name
        }
        cause
      }
    }
  }
`;

export const DELETE_MARKED_SCHEDULE = gql`
  mutation deleteMarkedSchedule($key: ID!) {
    deleteMarkedSchedule(key: $key)
  }
`;

export const IMPORT_SCHEDULES = `
  mutation importSchedulesExcel($input: ImportSchedulesExcelInput!) {
    importSchedulesExcel(input: $input)
  }
`;

export const CREATE_CAUSE = gql`
  mutation createCause($input: CreateCauseInput!) {
    createCause(input: $input) {
      id
      key
      scheduleKey
      types {
        id
        key
        name
      }
      subcontractor {
        key
        name
      }
      cause
    }
  }
`;

export const UPDATE_CAUSE = gql`
  mutation updateCause($input: UpdateCauseInput!) {
    updateCause(input: $input) {
      id
      key
      scheduleKey
      types {
        id
        key
        name
      }
      subcontractor {
        key
        name
      }
      cause
    }
  }
`;

export const CREATE_RESTRICTION = gql`
  mutation createRestriction($input: CreateRestrictionInput!) {
    createRestriction(input: $input) {
      key
      name
      scheduleName
      startDate
      type {
        key
        name
      }
      user {
        key
        name
        lastName
        lastName2
        dni
        email
      }
      liftingDate
      status
    }
  }
`;

export const UPDATE_RESTRICTION = gql`
  mutation updateRestriction($input: UpdateRestrictionInput!) {
    updateRestriction(input: $input) {
      key
      name
      scheduleName
      startDate
      type {
        key
        name
      }
      user {
        key
        name
        lastName
        lastName2
        dni
        email
      }
      liftingDate
      status
    }
  }
`;

export const DELETE_RESTRICTION = gql`
  mutation deleteRestriction($key: ID!) {
    deleteRestriction(key: $key)
  }
`;

export const CALCULATE_PPC = gql`
  mutation calculatePPC($input: UpdatePPCInput!) {
    calculatePPC(input: $input) {
      id
      key
      name
      initialDate
      ppc
      schedule(type: Weekly) {
        id
        key
        weekKey
        name
        date
        hour
        status
        color
        isToDispatch
        sort
        unit
        metered
        departure {
          id
          key
          name
        }
        sector {
          key
          name
        }
        user {
          id
          key
          name
          lastName
          lastName2
          dni
          email
          company
        }
        cause {
          id
          key
          scheduleKey
          types {
            id
            key
            name
          }
          cause
        }
      }
    }
  }
`;

export const CALCULATE_PPC_BY_DEPARTURES = gql`
  mutation calculatePPCByDepartures($input: UpdatePPCInput!) {
    calculatePPCByDepartures(input: $input) {
      id
      key
      name
      initialDate
      ppc
      schedule(type: Weekly) {
        id
        key
        weekKey
        name
        date
        hour
        status
        color
        isToDispatch
        sort
        unit
        metered
        departure {
          id
          key
          name
        }
        user {
          id
          key
          name
          lastName
          lastName2
          dni
          email
          company
        }
        cause {
          id
          key
          scheduleKey
          types {
            id
            key
            name
          }
          cause
        }
        sector {
          key
          name
        }
      }
    }
  }
`;

export const GENERATE_PLANNIG_SCHEDULES_REPORT = gql`
  mutation generatePlannigSchedulesReport($input: PlannigReportInput!) {
    generatePlannigSchedulesReport(input: $input)
  }
`;

export const GENERATE_WEEKLY_PLANNING_REPORT = gql`
  mutation generateWeeklyPlanningReport($input: PlannigReportInput!) {
    generateWeeklyPlanningReport(input: $input)
  }
`;

export const CREATE_PLAN = gql`
  mutation createPlane($input: CreatePlaneInput!) {
    createPlane(input: $input) {
      id
      key
      name
      type
      workKey
      image
      convertedImage
      urn
      progress
      speciality {
        id
        key
        name
      }
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation updatePlane($input: UpdatePlaneInput!) {
    updatePlane(input: $input) {
      id
      key
      name
      type
      workKey
      image
      convertedImage
      urn
      progress
      speciality {
        id
        key
        name
      }
    }
  }
`;

export const DELETE_PLAN = gql`
  mutation deletePlane($key: ID!) {
    deletePlane(key: $key)
  }
`;

export const CREATE_PLAN_DIARY = `
  mutation createPlaneDiary($input: CreatePlaneDiaryInput!) {
    createPlaneDiary(input: $input) {
      id
      key
      workKey
      specialityKey
      image
      legend {
        name
        color
      }
      date
    }
  }
`;

export const DELETE_PLAN_DIARY = gql`
  mutation deletePlaneDiary($key: ID!) {
    deletePlaneDiary(key: $key)
  }
`;

export const GENERATE_TASKER_WEEKLY_REPORT = gql`
  mutation generateReport($input: ExcelReportInput!) {
    generateReport(input: $input)
  }
`;

export const GENERATE_TASKER_WEEKLY_FORMAT_OPERA_REPORT = gql`
  mutation generateFormatOperaReport($input: ExcelReportInput!) {
    generateFormatOperaReport(input: $input)
  }
`;

export const GENERATE_TASKER_WEEKLY_FORMAT_S10V2_REPORT = gql`
  mutation generateFormatS10v2Report($input: ExcelReportInput!) {
    generateFormatS10v2Report(input: $input)
  }
`;

export const GENERATE_WORSHOURS_REPORT = gql`
  mutation FindAllWorkHoursAndCategoryCost($filter: ReportFilter!) {
    findAllWorkHoursAndCategoryCost(filter: $filter)
  }
`;

export const GENERATE_TASKER_HISTORY_REPORT = gql`
  mutation generateHistoryReport($input: ExcelReportInput!) {
    generateHistoryReport(input: $input)
  }
`;

export const GENERATE_TASKER_HISTOGRAM_REPORT = gql`
  mutation generateTaskerHistogramReport($input: ExcelReportInput!) {
    generateTaskerHistogramReport(input: $input)
  }
`;

export const GENERATE_TASKER_TEMPLATE_WEEKLY_REPORT = gql`
  mutation generateTaskerTemplateWeeklyReport($input: ExcelReportInput!) {
    generateTaskerTemplateWeeklyReport(input: $input)
  }
`;

export const GENERATE_TASKER_TEMPLATE_DAILY_REPORT = gql`
  mutation generateTaskerTemplateDailyReport($input: ExcelReportInput!) {
    generateTaskerTemplateDailyReport(input: $input)
  }
`;

export const GENERATE_TASKER_WEEKLY = gql`
  mutation generateTaskerWeekly($input: ExcelReportInput!) {
    generateTaskerWeekly(input: $input)
  }
`;

export const GENERATE_TASKER_ACCUMULATIVE = gql`
  mutation generateTaskerAccumulative($input: ExcelReportInput!) {
    generateTaskerAccumulative(input: $input)
  }
`;

export const ENABLE_WORK_CATEGORIES = gql`
  mutation enableWorkCategories($filter: WorkCategoryFilter!) {
    enableWorkCategories(filter: $filter) {
      key
      categoryKey
      categoryName
      unitCost
    }
  }
`;

export const CREATE_WORK_CATEGORY = gql`
  mutation createWorkCategory($input: CreateWorkCategoryInput!) {
    createWorkCategory(input: $input) {
      key
      categoryKey
      categoryName
      unitCost
    }
  }
`;

export const UPDATE_WORK_CATEGORY = gql`
  mutation updateWorkCategory($input: UpdateWorkCategoryInput!) {
    updateWorkCategory(input: $input) {
      key
      categoryKey
      categoryName
      unitCost
    }
  }
`;

export const CONFIRM_SECOND_TASKER = gql`
  mutation confirmSecondTasker($input: TaskerConfirmationInput!) {
    confirmSecondTasker(input: $input) {
      status
      date
      workKey
      workName
      firstUserEmail
      secondUserEmail
    }
  }
`;

export const CONFIRM_SECOND_TASKER_DAILY = gql`
  mutation confirmSecondTaskerDaily($input: TaskerDailyConfirmationInput!) {
    confirmSecondTaskerDaily(input: $input) {
      type
      date
      workKey
      userKey
      userEmail
      isConfirmed
    }
  }
`;

export const CREATE_TASKER_DAILY_CONFIG = gql`
  mutation createTaskerDailyConfig($input: CreateTaskerDailyConfigInput!) {
    createTaskerDailyConfig(input: $input) {
      key
      date
      isClosed
    }
  }
`;

export const UPDATE_TASKER_DAILY_CONFIG = gql`
  mutation updateTaskerDailyConfig($input: UpdateTaskerDailyConfigInput!) {
    updateTaskerDailyConfig(input: $input) {
      key
      date
      isClosed
    }
  }
`;

export const HANDLE_TASKER_DAILY_CONFIG_BY_WEEK = gql`
  mutation handleTaskerDailyConfigByWeek(
    $input: HandleTaskerDailyConfigInput!
  ) {
    handleTaskerDailyConfigByWeek(input: $input)
  }
`;

export const CREATE_TASKER_WEEKLY_CONFIG = gql`
  mutation createTaskerWeeklyConfig($input: CreateTaskerWeeklyConfigInput!) {
    createTaskerWeeklyConfig(input: $input) {
      key
      initialDate
      isClosed
    }
  }
`;

export const UPDATE_TASKER_WEEKLY_CONFIG = gql`
  mutation updateTaskerWeeklyConfig($input: UpdateTaskerWeeklyConfigInput!) {
    updateTaskerWeeklyConfig(input: $input) {
      key
      initialDate
      isClosed
    }
  }
`;

export const SYNC_TASKER_WEEKLY_TO_CASISTEMAS = gql`
  mutation syncTaskerWeeklyToCASistemas($input: SyncTaskerWeeklyInput!) {
    syncTaskerWeeklyToCASistemas(input: $input)
  }
`;

export const CREATE_SUBCONTRACTOR = gql`
  mutation createSubcontractor($input: CreateSubcontractorInput!) {
    createSubcontractor(input: $input) {
      key
      name
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_SUBCONTRACTOR = gql`
  mutation updateSubcontractor($input: UpdateSubcontractorInput!) {
    updateSubcontractor(input: $input) {
      key
      name
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_DEPARTURE_IP = gql`
  mutation createDepartureIp($input: CreateDepartureIpInput!) {
    createDepartureIp(input: $input) {
      key
      departureKey
      sectorKey
      advance
      date
    }
  }
`;

export const UPDATE_DEPARTURE_IP = gql`
  mutation updateDepartureIp($input: UpdateDepartureIpInput!) {
    updateDepartureIp(input: $input) {
      key
      departureKey
      sectorKey
      advance
      date
    }
  }
`;

export const GENERATE_DEPARTURE_IP_REPORT_EXCEL = gql`
  mutation generateDepartureIpReportExcel($filter: DepartureIpReportFilter!) {
    generateDepartureIpReportExcel(filter: $filter)
  }
`;

export const GENERATE_DEPARTURE_IP_RESUME_EXCEL = gql`
  mutation generateDepartureIpResumeExcel($filter: DepartureIpResumeFilter!) {
    generateDepartureIpResumeExcel(filter: $filter)
  }
`;

export const CREATE_SECTOR = gql`
  mutation createSector($input: CreateSectorInput!) {
    createSector(input: $input) {
      key
      name
      code
      stage
    }
  }
`;

export const UPDATE_SECTOR = gql`
  mutation updateSector($input: UpdateSectorInput!) {
    updateSector(input: $input) {
      key
      name
      code
      stage
    }
  }
`;

export const CREATE_SECTOR_DEPARTURE_ADVANCE = gql`
  mutation createSectorDepartureAdvance(
    $input: CreateSectorDepartureAdvanceInput!
  ) {
    createSectorDepartureAdvance(input: $input) {
      key
      departureKey
      sectorKey
      advance
      departure {
        key
        name
      }
    }
  }
`;

export const UPDATE_SECTOR_DEPARTURE_ADVANCE = gql`
  mutation updateSectorDepartureAdvance(
    $input: UpdateSectorDepartureAdvanceInput!
  ) {
    updateSectorDepartureAdvance(input: $input) {
      key
      departureKey
      sectorKey
      advance
      departure {
        key
        name
      }
    }
  }
`;

export const IMPORT_SECTOR_DEPARTURE_ADVANCES = `
  mutation importSectorDepartureAdvances($input: SectorDepartureAdvanceExcelInput!) {
    importSectorDepartureAdvances(input: $input)
  }
`;

export const ENABLE_JORNAL = gql`
  mutation enableJornal($input: JornalInput!) {
    enableJornal(input: $input) {
      key
      name
      weekday
      hours
    }
  }
`;

export const UPDATE_JORNAL = gql`
  mutation updateJornal($input: UpdateJornalInput!) {
    updateJornal(input: $input) {
      key
      name
      hours
    }
  }
`;

export const AUTHORIZE_WORK_HOUR_ITEM = gql`
  mutation authorizeWorkHourItem($key: ID!) {
    authorizeWorkHourItem(key: $key)
  }
`;

export const DELETE_WORK_HOUR_ITEM = gql`
  mutation deleteWorkHourItem($key: ID!) {
    deleteWorkHourItem(key: $key)
  }
`;

export const CREATE_WEEK_DEPARTURE = gql`
  mutation createWeekDeparture($input: CreateWeekDepartureInput!) {
    createWeekDeparture(input: $input) {
      key
      departureKey
      userKey
      initialDate
      sort
    }
  }
`;

export const UPDATE_WEEK_DEPARTURE = gql`
  mutation updateWeekDeparture($input: UpdateWeekDepartureInput!) {
    updateWeekDeparture(input: $input) {
      key
      departureKey
      userKey
      initialDate
      sort
    }
  }
`;

export const DELETE_WEEK_DEPARTURE = gql`
  mutation deleteWeekDeparture($key: ID!) {
    deleteWeekDeparture(key: $key)
  }
`;

export const GENERATE_WEEK_DEPARTURES = gql`
  mutation generateWeekDepartures($input: GenerateWeekDepartureInput!) {
    generateWeekDepartures(input: $input) {
      departureKey
      userKey
      initialDate
      sort
    }
  }
`;

export const IMPORT_TASKER_WEEKLY = `
  mutation importTaskerWeekly($input: ImportTaskerWeeklyInput!) {
    importTaskerWeekly(input: $input)
  }
`;
